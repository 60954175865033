import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './shared/services/auth.service';
import { CustomStorageService } from './shared/services/custom-storage.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { HttpConfigInterceptor } from './shared/interceptors/http-config.interceptor';
import { TopBarComponent } from './layouts/top-bar/top-bar.component';
import { SideMenuComponent } from './layouts/side-menu/side-menu.component';
import { DropdownModule } from 'primeng/dropdown';
import { MessageService } from 'primeng/api';
import { FormsModule } from '@angular/forms';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/app/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        ContentLayoutComponent,
        TopBarComponent,
        SideMenuComponent,
        AuthLayoutComponent
    ],
    imports: [
        // angular
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            isolate: true
        }),

        // app
        AppRoutingModule,

        // primeng
        DropdownModule
    ],
    providers: [
        CustomStorageService,
        MessageService,
        //AuthService,
        { provide: HTTP_INTERCEPTORS,
          useClass: HttpConfigInterceptor,
          multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
