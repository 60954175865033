import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

    isActive = false;
    lang;

    constructor(private authService: AuthService,
                private languageService: LanguageService,
                private translate: TranslateService)
                 { }

    ngOnInit() {
        this.lang = this.languageService.getLang();
    }

    toggleAddUser() {
        this.isActive = !this.isActive;
    }

    changeLang(newLang) {
        this.translate.setDefaultLang(newLang);
        this.languageService.setLang(newLang);

        this.lang = this.languageService.getLang();
    }
    
    logout() {
        this.authService.logout();
        location.reload(true);
    }
}
