import { Component, OnInit } from '@angular/core';
import { ClinicService } from 'src/app/shared/services/clinic.service';
import { SelectItem } from 'primeng/api';
import { Clinic } from 'src/app/shared/models/Clinic';
import { AuthService } from 'src/app/shared/services/auth.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

    currentClinic: Clinic;
    clinicItems: SelectItem[] = [];

    constructor(private authService: AuthService, private clinicService: ClinicService) { }

    ngOnInit() {
        const currentUser = this.authService.getUserLogged();
        this.currentClinic = this.clinicService.getCurrentClinic();

        if (currentUser.user_metadata.isCmqAdmin) {

            this.clinicService.getAll().subscribe((clinics) => {
                this.clinicItems = clinics.map((clinic) => {
                    return {
                        label: `${clinic.name} (${clinic.id})`,
                        value: clinic
                    };
                }).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            });
        } else {
            const clinics = currentUser.user_metadata.entities.map((clinic) => {
                return this.clinicService.getById(clinic.entityId);
            });

            forkJoin(clinics).subscribe((results) => {
                this.clinicItems = results.map((clinic) => {
                    return {
                        label: clinic.name,
                        value: clinic
                    };
                }).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            });
        }
    }

    onCurrentClinicChange() {
        this.clinicService.setCurrentClinic(this.currentClinic);
    }
}
