import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Clinic } from '../models/Clinic';

import { environment } from 'src/environments/environment';
import { appConfig } from 'src/app/app.config';
import { AtRvRelationship } from '../models/AppConfig';

@Injectable({
    providedIn: 'root',
})
export class ClinicService {
    private clinicUrl = 'pub/superEntities';
    private currentClinic: Clinic;

    constructor(private http: HttpClient) {
        this.clinicUrl = environment.restapi.concat(this.clinicUrl);
    }

    getClinicId(): number {
        return this.currentClinic.id;
    }

    getClinicEmrID(): string {
        if (localStorage.getItem('clinicEmrId'))
            return localStorage.getItem('clinicEmrId');

        return this.currentClinic.idClinicDME;
    }

    getCurrentClinic(): Clinic {
        return this.currentClinic;
    }

    setCurrentClinic(clinic: Clinic) {
        this.currentClinic = clinic;
        localStorage.setItem('clinicEmrId', clinic.idClinicDME)
    }

    getById(id: number): Observable<Clinic> {
        return this.http.get<Clinic>(`${this.clinicUrl}/${id}`);
    }

    getAll(): Observable<Clinic[]> {
        return this.http.get<Clinic[]>(this.clinicUrl, {
            params: {
                type: 'cl'
            }
        });
    }

    isParamedical(): boolean {
        return appConfig.dme[this.currentClinic.dme.id].isParaMedical;
    }

    isNewEmr(): boolean {
        return appConfig.dme[this.currentClinic.dme.id].isNewEmr;
    }

    atRvRelationshipIsOneToOne(): boolean {
        return appConfig.dme[this.currentClinic.dme.id].atRvRelationship === AtRvRelationship.oneToOne;
    }

    atRvRelationshipIsManyToMany(): boolean {
        return appConfig.dme[this.currentClinic.dme.id].atRvRelationship === AtRvRelationship.manyToMany;
    }
}
