import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Authentication } from '../models/Authentication';
import { User } from '../models/User';
import { CustomStorageService } from 'src/app/shared/services/custom-storage.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private authUrl = '';
    private headers: HttpHeaders;
    private currentUser: User;

    constructor(private http: HttpClient, private storage: CustomStorageService) {
        const activeUser: any = this.storage.getItem('currentUser');
        this.currentUser = this.parseUser(activeUser);
        this.authUrl = environment.restapi.concat(this.authUrl);
        this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    }

    public getUserLogged(): User {
        return this.currentUser;
    }

    public setUserLogged(user: User) {
        this.currentUser = user;
    }

    login(username: string, password: string): Observable<User> {
        const formData = new URLSearchParams();
        formData.set('username', username);
        formData.set('password', password);

        return this.http.post<Authentication>(`${this.authUrl}login`, formData.toString(), { headers: this.headers }).pipe(
            switchMap(authentication => {

                // login successfull if there's a token in the response
                if (authentication && authentication.access_token) {

                    return this.getUserInfo(authentication.id_token).pipe(
                        switchMap(user => {
                            if (user && user.user_id) {

                                // store token in local storage to keep user logged in between page refreshes
                                this.storage.setItem('currentUser', JSON.stringify(user));
                                localStorage.setItem('token', authentication.id_token);
                                this.currentUser = user;
                                return of(user);
                            } else {
                                return throwError(new Error('User not found'));
                            }
                        })
                    );
                } else {
                    return throwError(new Error('Bad credentials'));
                }
            })
        );
    }

    logout() {
        localStorage.removeItem('token');
    }

    getUserInfo(token: string): Observable<User> {
        const formData = new URLSearchParams();
        formData.set('token', token);

        return this.http.post<User>(`${this.authUrl}tokeninfo`, formData.toString(), { headers: this.headers });
    }

    private parseUser(user: User): User | null {
        let userJson;

        if (!user) {
            return null;
        }

        if (typeof user === 'string' || user instanceof String) {
            userJson = JSON.parse(user.toString());
        }

        return {
            global_client_id: user.global_client_id || userJson.global_client_id,
            email_verified: user.email_verified || userJson.email_verified,
            clientID: user.clientID || userJson.clientID,
            identities: user.identities || userJson.identities,
            user_metadata: user.user_metadata || userJson.user_metadata,
            updated_at: user.updated_at || userJson.updated_at,
            created_at: user.created_at || userJson.created_at,
            user_id: user.user_id || userJson.user_id,
            nickname: user.nickname || userJson.nickname,
            name: user.name || userJson.name,
            email: user.email || userJson.email,
            picture: user.picture || userJson.picture
        };
    }
}
