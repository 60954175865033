import { AppConfig, AtRvRelationship } from './shared/models/AppConfig';

export const appConfig: AppConfig = {
    dme: {
        1: {
            name: 'omd',
            url: 'omd',
            atRvRelationship: AtRvRelationship.oneToMany,
            isMedical: true,
            isParaMedical: false,
            isNewEmr: false
        },
        2: {
            name: 'kin',
            url: 'kin',
            atRvRelationship: AtRvRelationship.oneToMany,
            isMedical: true,
            isParaMedical: false,
            isNewEmr: false
        },
        3: {
            name: 'medesync',
            url: 'telus',
            atRvRelationship: AtRvRelationship.oneToOne,
            isMedical: true,
            isParaMedical: false,
            isNewEmr: false
        },
        4: {
            name: 'medaccess',
            url: 'telus',
            atRvRelationship: AtRvRelationship.oneToOne,
            isMedical: true,
            isParaMedical: false,
            isNewEmr: false
        },
        5: {
            name: 'std',
            url: 'pub',
            atRvRelationship: AtRvRelationship.oneToMany,
            isMedical: true,
            isParaMedical: false,
            isNewEmr: false
        },
        7: {
            name: 'pss',
            url: 'telus',
            atRvRelationship: AtRvRelationship.oneToMany,
            isMedical: true,
            isParaMedical: false,
            isNewEmr: false
        },
        9: {
            name: 'qhr',
            url: 'accuro',
            atRvRelationship: AtRvRelationship.oneToOne,
            isMedical: true,
            isParaMedical: false,
            isNewEmr: true
        },
        10: {
            name: 'clinicmaster',
            url: 'clinicmaster',
            atRvRelationship: AtRvRelationship.manyToMany,
            isMedical: false,
            isParaMedical: true,
            isNewEmr: false
        },
        11: {
            name: 'mobilemed',
            url: 'mm',
            atRvRelationship: AtRvRelationship.oneToOne,
            isMedical: true,
            isParaMedical: false,
            isNewEmr: true
        },
        12: {
            name: 'medrv',
            url: 'mrv',
            atRvRelationship: AtRvRelationship.oneToOne,
            isMedical: true,
            isParaMedical: false,
            isNewEmr: true
        },
        13: {
            name: 'abeldent',
            url: 'abeldent',
            atRvRelationship: AtRvRelationship.oneToOne,
            isMedical: false,
            isParaMedical: true,
            isNewEmr: true
        }
    }
};
