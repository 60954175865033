import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [{
    path: '',
    redirectTo: '/communications',
    pathMatch: 'full'
}, {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [{
        path: '',
        loadChildren: './modules/auth/auth.module#AuthModule'
    }]
}, {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    children: [{
        path: 'communications',
        loadChildren: './modules/communications/communications.module#CommunicationsModule'
    }, {
        path: 'invites',
        loadChildren: './modules/patientportal-invitations/patientportal-invitations.module#PatientPortalInvitationsModule'
    }, {
        path: 'templates',
        loadChildren: './modules/templates/templates.module#TemplatesModule'
    }, {
        path: 'broadcasts',
        loadChildren: './modules/broadcasts/broadcasts.module#BroadcastsModule'
    }, {
        path: 'links',
        loadChildren: './modules/partners/partners.module#PartnersModule'
    }]
}];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
