import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { ClinicService } from '../services/clinic.service';
import { ConfigurationService } from '../services/configuration.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private clinicService: ClinicService,
        private configurationService: ConfigurationService
    ) { }

    canActivate(): Observable<boolean> {
        if (localStorage.getItem('token')) {

            if (!this.authService.getUserLogged()) {
                return this.authService.getUserInfo(localStorage.getItem('token')).pipe(
                    switchMap(user => {
                        this.authService.setUserLogged(user);
                        return this.clinicService.getById(user.user_metadata.entities[0].entityId);
                    }),
                    switchMap(clinic => {
                        this.clinicService.setCurrentClinic(clinic);
                        return this.configurationService.getAll();
                    }),
                    switchMap(configs => {
                        this.configurationService.setConfigurations(configs);
                        return of(true);
                    }),
                    catchError(_ => {
                        this.router.navigate(['/auth']);
                        return of(false);
                    })
                );
            } else {
                return of(true);
            }
        }

        this.router.navigate(['/auth']);
        return of(false);
    }
}
