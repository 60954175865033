import { Injectable } from "@angular/core";

@Injectable()
export class CustomStorageService {    
    setLocalItem(key, value){
        localStorage.setItem(key, value);
    }

    getLocalItem(key){
        return localStorage.getItem(key);
    }

    removeLocalItem(key){
        localStorage.removeItem(key);
    }

    clearLocal(){
        localStorage.clear();
    }

    setItem(key, value){
        sessionStorage.setItem(key, value);
    }

    getItem(key){
        return sessionStorage.getItem(key);
    }

    removeItem(key){
        sessionStorage.removeItem(key);
    }

    clear(){
        sessionStorage.clear();
    }
}
