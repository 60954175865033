import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { LanguageService } from './shared/services/language.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private langSubscription: Subscription;

    constructor(private translate: TranslateService, private languageService: LanguageService) {
        const browserLang = translate.getBrowserLang();
        let lang: string;
        if (localStorage.getItem('cmqlang')) {
            lang = localStorage.getItem('cmqlang');
        } else {
            lang = browserLang.match(/en|fr/) ? browserLang : 'en';
        }
        this.translate.setDefaultLang(lang);
        this.languageService.setLang(lang);
    }

    ngOnInit(): void {
        this.langSubscription = this.languageService.lang$.subscribe(lang => this.translate.use(lang));
    }

    ngOnDestroy() {
        if (this.langSubscription) {
            this.langSubscription.unsubscribe();
        }
    }
}
