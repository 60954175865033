export class AppConfig {
    dme: {
        [id: number]: {
            name: string;
            url: string;
            atRvRelationship: AtRvRelationship;
            isMedical: boolean;
            isParaMedical: boolean;
            isNewEmr: boolean;
        }
    };
}

export enum AtRvRelationship {
    oneToOne = 'oneToOne',
    oneToMany = 'oneToMany',
    manyToMany = 'manyToMany'
}
