import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Config, ConfigCreation } from '../models/Config';
import { Task } from '../models/Task';
import { ClinicService } from './clinic.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    private configBasicUrl = 'basic/configurations';
    private configPubUrl = 'pub/configurations';
    private configUrl = 'configurations';
    private configs: Config[];

    constructor(private http: HttpClient, private clinicService: ClinicService) {
        this.configBasicUrl = environment.restapi.concat(this.configBasicUrl);
        this.configPubUrl = environment.restapi.concat(this.configPubUrl);
        this.configUrl = environment.restapi.concat(this.configUrl);
    }

    create(config: ConfigCreation): Observable<Config> {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let params = new HttpParams({
            fromObject: {
                name: config.name,
                value: config.value,
                idSuperEntity: this.clinicService.getClinicId().toString()
            }
        });

        if (config.idUser) {
            params = new HttpParams({
                fromObject: {
                    name: config.name,
                    value: config.value,
                    idSuperEntity: this.clinicService.getClinicId().toString(),
                    idUser: config.idUser.toString()
                }
            });
        }

        return this.http.post<Config>(this.configUrl, params, { headers });
    }

    update(id: number, config: Config): Observable<Config> {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const params = new HttpParams({
            fromObject: {
                value: config.value
            }
        });

        return this.http.put<Config>(`${this.configUrl}/${id}`, params, { headers });
    }

    getByName(name: string): Observable<Config[]> {
        return this.http.get<Config[]>(`${this.configPubUrl}`, {
            params: {
                idSuperEntity: this.clinicService.getClinicId().toString(),
                name
            }
        });
    }

    getByUserId(idUser: number): Observable<Config[]> {
        return this.http.get<Config[]>(`${this.configPubUrl}`, {
            params: {
                idSuperEntity: this.clinicService.getClinicId().toString(),
                idUser: idUser.toString()
            }
        });
    }

    getAll(): Observable<Config[]> {
        return this.http.get<Config[]>(this.configBasicUrl, {
            params: {
                idSuperEntity: this.clinicService.getClinicId().toString()
            }
        });
    }

    hasTasks(config: Config, tasks: Task[]): boolean {
        for (const task of tasks) {
            if (!config.value.split('-').includes(task.id.toString())) {
                return false;
            }
        }
        return true;
    }

    isDocTask(config: Config) {
        return (config.name === 'PRVDocTasks' || config.name === 'OtherDocTasks'
            || config.name === 'NoMDDocTasks'
            || config.name === 'NoMRDocTasks'
            || config.name === 'NoNAMDocTasks');
    }

    setConfigurations(configs: Config[]) {
        this.configs = configs;
    }

    isOnNewPricingMOdel(): boolean {
        const config = this.configs.find(c => {
            return c.name === 'IsOnNewPricing';
        });

        if (config) {
            return config.value === '1';
        }

        return false;
    }
}

