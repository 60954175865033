import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = localStorage.getItem('token');

        if (!request.headers.has('Content-Type') && (request.method === 'POST' || request.method === 'PUT')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/x-www-form-urlencoded') });
        } else if (request.headers.has('Content-Type') && request.headers.get('Content-Type') === 'undefined') {
            request = request.clone({ headers: request.headers.delete('Content-Type') });
        }

        if (token && request.url.indexOf('amazonaws') === -1) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        return next.handle(request).pipe(
            catchError(err => {
                if (err.status === 401) {
                    this.authService.logout();
                    location.reload(true);
                }

                return throwError(err);
            }));
    }
}
