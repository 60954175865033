import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    public lang$: Subject<string>;
    private lang: string;

    constructor() {
        this.lang$ = new Subject<string>();
    }

    getLang(): string {
        return this.lang;
    }

    setLang(lang: string) {
        this.lang = lang;
        localStorage.setItem('cmqlang', lang);
        this.lang$.next(lang);
    }
}
